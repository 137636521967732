import { createMuiTheme } from "@material-ui/core"
import red from "@material-ui/core/colors/red"

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ff6600",
      contrastText: "#fff",
    },

    secondary: {
      main: "#06d6a0",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
  },
  typography: {
    fontFamily: [
      '"Montserrat"',
      "Raleway",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
})

export default theme
